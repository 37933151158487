/**
 * Copyright 2016 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../variables";
@import "../mixins";

.mdl-expansion {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  box-sizing: border-box;

  &__summary {
    @include typo-preferred-font;
    padding-left: 24px;
    padding-right: 24px;
    height: 48px;
    display: flex;
    outline: none;

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      @include typo-icon;
      content: '\e313';
      display: inline-flex;
      flex-direction: column;
      user-select: none;
      justify-content: center;
      transition: transform 200ms, color 200ms;
      margin-left: auto;
      color: rgba(0, 0, 0, .38);
    }

    &:focus {
      background-color: unquote("rgba(#{$palette-grey-200}, 1)");
      &::after {
        color: rgba(0, 0, 0, .54);
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.9375rem;
    font-weight: 500;
    flex-basis: 30%;
    margin-right: 16px;
  }

  &__subheader {
    font-size: .75rem;
    color: rgba(0, 0, 0, .54);
  }

  &__secondary-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.9375rem;
    font-weight: 500;
    flex-basis: 30%;
    color: rgba(0, 0, 0, .87);
    margin-right: 16px;
  }

  &__content {
    @include typo-preferred-font;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    &+.mdl-expansion__actions {
      border-top: 1px solid rgba(0, 0, 0, .12);
    }
  }

  &__actions {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &__action {
    margin-right: 8px;
  }

  &[open] {
    .mdl-expansion__summary::after {
      transform: rotate(180deg);
    }
  }
}
